.header {
  background-color: black;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
  .logo{
    img {
        width: 75px;
        height: 75px;
    }
  }
  
  ul {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
      background-color: black;
    }
    li a{
      display: block;
      padding: 20px 20px;
      border-right: 1px solid #f4f4f4;
      text-decoration: none;
      color: white;
      align-items: center;
    }
}

.header li a:hover,
.header .menu-btn:hover {
  color: black;
  background-color: #f4f4f4;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: white;
  display: block;
  height: 4px;
  position: relative;
  transition: background .2s ease-out;
  width: 30px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: white;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 10px;
}

.header .menu-icon .navicon:after {
  top: -10px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 313px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header li a {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 1520px) {
//     .services {
//         video {
//             height: 600px;
//         }
//         .intro {
//             position: relative;
//             .text{
//                 font-size: 31px;
//                 p {
//                     margin:20px 0;
//                 }
//             }
//             .pics {
//                 img {
//                     width: 100%;
//                 }
//             }
//         }
//         .content {
//             margin-top: 0%;
//             height: 1687px;
//             img {
//                 height: 1687px;
//             }
//             .services {
//                 top: 141%;
//                 width: 100%;
//                 h1{
//                     text-shadow: 2px 2px 4px #000000;
//                 }
//                 .offers {
//                     flex-direction: column;
//                     margin: auto;
//                     p{
//                         font-size: 20px;
//                     }
//                     div {
//                         width: 82%;
//                     }
//                 }
//             }   
//         }
//         .outro {
//             h1 {
//                 font-size: 30px;
//                 padding: 3%;
//             }
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 1280px) {
//     .services {
//         video {
//             height: 600px;
//         }
//         .intro {
//             position: relative;
//             .text{
//                 font-size: 31px;
//                 p {
//                     margin:20px 0;
//                 }
//             }
//             .pics {
//                 img {
//                     width: 100%;
//                 }
//             }
//         }
//         .content {
//             margin-top: 0%;
//             height: 1790px;
//             img {
//                 height: 1790px;
//             }
//             .services {
//                 top: 141%;
//                 width: 100%;
//                 h1{
//                     text-shadow: 2px 2px 4px #000000;
//                 }
//                 .offers {
//                     flex-direction: column;
//                     margin: auto;
//                     p{
//                         font-size: 20px;
//                     }
//                     div {
//                         width: 82%;
//                     }
//                 }
//             }   
//         }
//         .outro {
//             h1 {
//                 font-size: 30px;
//                 padding: 3%;
//             }
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// // @media screen and (max-width: 1280px) {
// //     .services {
// //         video {
// //             height: 600px;
// //         }
// //         .intro {
// //             position: relative;
// //             .text{
// //                 font-size: 31px;
// //                 p {
// //                     margin:20px 0;
// //                 }
// //             }
// //             .pics {
// //                 img {
// //                     width: 100%;
// //                 }
// //             }
// //         }
// //         .content {
// //             margin-top: 0%;
// //             height: 1687px;
// //             img {
// //                 height: 1687px;
// //                 object-fit: cover;
// //             }
// //             .services {
// //                 top: 141%;
// //                 width: 100%;
// //                 h1{
// //                     text-shadow: 2px 2px 4px #000000;
// //                 }
// //                 .offers {
// //                     flex-direction: column;
// //                     margin: auto;
// //                     p{
// //                         font-size: 20px;
// //                     }
// //                     div {
// //                         width: 82%;
// //                     }
// //                 }
// //             }   
// //         }
// //         .outro {
// //             h1 {
// //                 font-size: 30px;
// //                 padding: 3%;
// //             }
// //         }
// //     }
// // }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 1024px) {
//     .services {
//         .intro {
//             .text{
//                 font-size: 23px;
//             }
//             .pics {
//                 img {
//                     width: 100%;
//                 }
//             }
//         }
//         .content {
//             height: 1847px;
//             img {
//                 height: 1847px;
//             }
//             .services {
//                 top: 114%;

//             }   
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 912px) {
//     .services {
//         .intro {
//             .text{
//                 font-size: 20px;
//                 padding-left: 4%;
//             }
//             .pics {
//                 img {
//                     &.services2 {
//                        width: 98%;
//                     }
//                 }
//             }
//         }
//         .content {
//             height: 1687px;
//             img {
//                 height: 1687px;
//             }
//             .services {
//                 top: 77%;
//                 .offers {
//                     h2 {
//                         font-size: 30px;
//                     }
//                 }
//             }   
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 820px) {
//     .services {
//         .content {
//             width: 100%;
//             height: 1790px;
//             img {
//                 height: 1790px;
//             }
//             .services {
//                 top: 114%;
//                 h1{
//                     width: 100%;
//                 }
//                 .offers {
//                     p{
//                         font-size: 20px;
//                     }
//                     div {
//                         width: 82%;
//                     }
//                 }
//             }   
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 768px) {
//     .services {
//         .content {
//             height: 2061px;
//             img {
//                 height: 2061px;
//             }
//             .services {
//                 top: 115%;
//             }   
//         }
//     }
// }

// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 540px) {
//     .services {
//         .intro {
//             .text{
//                 font-size: 12px;
//             }
//         }
//         .content {
//             height: 1922px;
//             img {
//                 height: 1922px;
//             }
//             .services {
//                 top: 123%;
//                 .offers {
//                     h2 {
//                         font-size: 25px;
//                     }
                    
//                 }
//             }   
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 470px) {
//     .services {
//         .intro {
//             flex-direction: column;
//             background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(124,156,155,1) 93%);
//             border-bottom: 1px solid white;
//             .text{
//                 font-size: 20px;
//                 width: 91%;
//                 p {
//                 margin:10px 0;
//                 padding: 0 10px;
    
//                 }
//             }
//             .pics {
//                 width: 97%;
//                 padding: 1px;
//                 img {
//                     width: 95%;
//                     height: 95%;
//                     &.services1 {
//                         top:auto;
//                         position: relative;
//                         width: 98%;
//                         height: auto;
//                         right: auto;
//                     }
//                 }
//             }
//         }
//         .content {
//             height: 1687px;
//             img {
//                 height: 1687px;
//             }
//             .services {
//                 top: 189%;
//                 .offers {
//                     h2 {
//                         font-size: 20px;
//                     }
//                     p{
//                         font-size: 15px;
//                     }
//                 }
//             }   
//         }
       
//         .outro {
//             h1 {
//                 font-size: 20px;
//             }
//             button {
//                 padding: 10px;
//                 &:hover {
//                     background-color: white;
//                     color: #141110;
//                 }
//             }
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 414px) {
//     .services {
//         .intro {
//             flex-direction: column;
//             background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(124,156,155,1) 93%);
//             border-bottom: 1px solid white;
//             .text{
//                 font-size: 20px;
//                 width: 91%;
//                 p {
//                 margin:10px 0;
//                 padding: 0 10px;
    
//                 }
//             }
//             .pics {
//                 width: 97%;
//                 padding: 1px;
//                 img {
//                     width: 95%;
//                     height: 95%;
//                     &.services1 {
//                         top:auto;
//                         position: relative;
//                         width: 98%;
//                         height: auto;
//                         right: auto;
//                     }
//                 }
//             }
//         }
//         .content {
//             height: 1687px;
//             img {
//                 height: 1687px;
//             }
//             .services {
//                 top: 175%;
//                 .offers {
//                     h2 {
//                         font-size: 20px;
//                     }
//                     p{
//                         font-size: 15px;
//                     }
//                 }
//             }   
//         }
       
//         .outro {
//             h1 {
//                 font-size: 20px;
//             }
//             button {
//                 padding: 10px;
//                 &:hover {
//                     background-color: white;
//                     color: #141110;
//                 }
//             }
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 390px) {
//     .services {
//         .content {
//             .services {
//                 top: 193%;
//             }   
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 400px) {
//     .services {
//         .content {
//             .services {
//                 top: 193%;
//             }   
//         }
//     }
// }
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 375px) {
//     .services {
//         .intro {
//             .text{
//                 width: 100%;
//                 color: white;
//                 padding: 0px;
//                 height: auto;
//             }
//             .pics {
//                 width: 100%;
//                 padding: 1px;
//             }
//         }
//         .content {
//             width: 100%;
//             height: 1887px;
//             img {
//                 height: 1887px;
//             }
//             .services {
//                 top: 250%;
//                 width: 100%;
                
//                 .offers {
//                     p{
//                         font-size: 15px;
//                     }
//                 }
//             }   
//         }
//     }
// }

// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// @media screen and (max-width: 375px) {
//     .services {
//         .intro {
//             .text{
//                 width: 100%;
//                 color: white;
//                 padding: 0px;
//                 height: auto;
//             }
//             .pics {
//                 width: 100%;
//                 padding: 1px;
//             }
//         }
//         .content {
//             width: 100%;
//             height: 2187px;
//             img {
//                 height: 2187px;
//             }
//             .services {
//                 top: 250%;
//                 width: 100%;
//                 h1 {
//                     width: 85%;
//                 }
//                 .offers {
//                     p{
//                         font-size: 15px;
//                     }
//                 }
//             }   
//         }
//     }
// }