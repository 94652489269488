.contact {
    height: 100%;
    width: 100%;
    .iframe {
        position: absolute;
        padding-top: 2.25%;
        height: 100%;
        width: 100%;
        iframe {
            border: none;
            position: absolute;
            top: 0;
            overflow-x: hidden;
            width: 100%;
            margin-top: 5%;
            height: 1040px;
        }
    }
    .content{
        position: absolute;
        z-index: 100;
        top: 20%;
        width: 100%;
        h1 {
            position: relative;
            color: white;
            text-align: center;
            width: 100%;
            font-size: 50px;
            font-weight: bold;
            text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.5);    

        }
        .form {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 3%;
            form {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 25px;
                padding: 10px;
                label {
                    font-size: 20px;
                    color: white;
                }
                input {
                    width: 80%;
                    height: 50%;
                    margin: 10px;
                    padding: 10px;
                    border: none;
                    border-radius: 5px;
                    font-size: 20px;
                    &:focus {
                        outline: none;
                    }
                }
                textarea {
                    width: 80%;
                    height: 100px;
                    margin: 10px;
                    border: none;
                    border-radius: 5px;
                    padding: 10px;
                    font-size: 20px;
                    &:focus {
                        outline: none;
                    }
                }
                button {
                    width: 50%;
                    height: 50px;
                    margin: 10px;
                    border: none;
                    border-radius: 5px;
                    padding: 10px;
                    font-size: 20px;
                    background-color: black;
                    color: white;
                    font-weight: bold;
                    &:hover {
                        background-color: white;
                        color: black;
                        cursor: pointer;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
            
        }
        .thanks {
            margin:0 auto;
            width: 100%;
            text-align: center;
            text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.5);
            p{
                color: white;
                font-size: 20px;
                font-weight: bold;
            }
            .social {
                width: 100%;
                margin: 0 auto;
                
                .ig {
                    width: 5%;
                    margin-right: 2%;
                    filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
                }
                .youtube {
                    width: 5%;
                    filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
                }
            }
        }
       
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1520px) {
    .contact {
        .iframe {
            iframe {
                margin-top: 0%;
                height: 853px;
            }
        }
        .content{
            // width: 124%;
            .form {
                form {
                    width: 40%;
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1280px) {
    .contact {

        .iframe {
            iframe {
                margin-top: 8%;
                height: 716px;
            }
        }
        .content{
            top: 15%;
            .form {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 3%;
                form {
                    width: 40%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1024px) {
    .contact {
        
        .content{
            // width: 130%;
            top: 26%;
            .form {
                form {
                    width: 50%;
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 912px) {
    .contact {
        img{
            object-fit: cover;
            width: 100%;
            &.clouds {
                width:100%;
            }
        }
        .content{
            .form {
                form {
                    width: 50%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 820px) {
    .contact {
        
        .content{
            // width: 130%;
            top: 26%;
            .form {
                form {
                    width: 50%;
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 768px) {
    .contact {
        .content {
            height: 2061px;
            
 
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 680px) {
    .contact {
        
        .content{
            .form {
                form {
                    width: 80%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
        
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 540px) {
    .contact {
        .iframe {
            iframe {
                margin-top: 19%;
                height: 304px;
            }
        }
        img{
            object-fit: cover;
            width: 100%;
            &.clouds {
                width:100%;
            }
        }
        .content{
            top: 48%;
            .form {
                form {
                    width: 80%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
                    button {
                        border: 2px solid white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 470px) {
    .contact {
        img{
            object-fit: cover;
            width: 100%;
            &.clouds {
                width:100%;
            }
        }
        .content{
            .form {
                form {
                    width: 80%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 414px) {
    .contact {
        img{
            object-fit: cover;
            width: 100%;
            &.clouds {
                width:100%;
            }
        }
        .content{
            height: 768px;
            .form {
                form {
                    width: 100%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
          
                }
                
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 390px) {
    .home {
        .content {

        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 400px) {
    .home {
        .content {
 
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 375px) {
    .contact {
        img{
            object-fit: cover;
            width: 100%;
            &.clouds {
                width:100%;
            }
        }
        .content{
            .form {
                form {
                    width: 100%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
                    input {
                        width: 89%;
                    }
                    textarea {
                        width: 89%;
                    }
          
                }
                
            }
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 320px) {
    .contact {
        .iframe {
            iframe {
                
            }
        }
        .content{
            .form {
                form {
                    width: 100%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
                    input {
                        width: 89%;
                    }
                    textarea {
                        width: 89%;
                    }
          
                }
                
            }
        }
    }
}