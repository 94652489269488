.Lost {
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    padding-top: 9%;
    text-align: center;
    .nav {
      width: 100%;
    }
    button {
        background-color: #141110;
        border: 2px solid white;
        border-radius: 25px;
        padding: 10px;
        font-size: 25px;
        margin: 20px;
        color: white;
        &:hover {
            background-color: white;
            color: #141110 !important;
        }
        a {
        text-decoration: none;
        color: white;
        padding:20px;
        &:hover {
            color: #141110 !important;
        }

        }
    }
}