.gallery {
    .title{
        h1 {
            text-align: center;
            position: absolute;
            top: 10%;
            z-index: 100;
            color: white;
            font-size: 82px;
            width: 100%;
            text-shadow: 2px 2px 4px #000000;
        }
    }
    .gallery-grid {
        position: relative;
        margin-top: 20%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .gallery-item {
            overflow: hidden;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            iframe {
                border:none;
                position:relative;
                top:0;
                width: 100%;
                height: 300px;
                object-fit: cover;
                
                &:hover {
                    transform: scale(1.05);
                }
            }

        }
    }

    .outro {
        background: black;
        color: white;
        text-align: center;
        width: 100%;
        padding:4% 0;
        h1 {
            font-size: 30px;
            position: relative;
        }
        button {
            background-color: #141110;
            border: 2px solid white;
            border-radius: 25px;
            padding: 10px;
            font-size: 25px;
            margin: 20px;
            color: white;
            &:hover {
                background-color: white;
                color: #141110 !important;
            }
            a {
            text-decoration: none;
            color: white;
            padding:20px;
            &:hover {
                color: #141110 !important;
            }

            }
        }
        p{
            font-size: 20px;
            font-weight: bold;
        }
        .social {
            width: 100%;
            margin: 0 auto;
            
            .ig {
                width: 5%;
                margin-right: 2%;
                filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
            }
            .youtube {
                width: 5%;
                filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1664px) {
    .gallery {
       .iframe {
            iframe {
                height: 939px;
                margin-top: 6%;
            }
       }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1520px) {
    .gallery {
        .iframe {
             iframe {
                height: 858px;
                margin-top: 7%;
             }
        }
     }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1334px) {
    .gallery {
        .iframe {
             iframe {
                height: 749px;
                margin-top: 7%;
             }
        }
     }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1024px) {
    .gallery {
        .title {
            h1 {
                top: 16%;
            }
        }
        .iframe {
            padding-top: 24%;
            iframe {
                margin-top: -14%;
                height: 581px;
            }
        }

    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 912px) {
    .gallery {
        .title {
            h1 {
                top: 11%;
            }
        }
        .gallery-grid {
            margin-top: 54%;
        }
        
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 820px) {
    .gallery {
        .title {
            h1 {
                top: 11%;
                font-size: 63px;
            }
        }
        .iframe {
            iframe {
                margin-top: -12%;
                height: 466px;
            }
        }
        .glance {
            width: 100%;
            color: white;
            h1 {
                text-align: center;
            }
            .glance-content {
                ul {
                    margin-left: 5%;
                }
            }
        }
        .content {
            p {
                width: 80%;
            }
        }
    }
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 540px) {
    .gallery {
        .title {
            h1 {
                top: 20%;
                font-size: 58px;
            }
        }
        .iframe {
            iframe {
                margin-top: -4%;
                height: 303px;
            }
        }
        .glance {
            h1 {
                font-size: 58px;
            }
            .glance-content {
                ul {
                    margin-left: 3%;
                }
            }
        }
        .content {
            h2 {
                font-size: 26px;
            }
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 470px) {
    .gallery {
        .title {
            h1 {
                top: 11%;
                font-size: 41px;
            }
        }
        .iframe {
            iframe {
                margin-top: -2%;
                height: 262px;
            }
        }
        .glance {
            h1 {
                font-size: 41px;
            }
            .glance-content {
                ul {
                    margin-left: 1%;
                }
            }
        }
        .content {
            h2 {
                font-size: 22px;
            }
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 400px) {
    .gallery {
        .title {
            // h1 {
            //     top: 16%;
            //     font-size: 50px;
            // }
        }
        .gallery-grid {
            margin-top: 54%;
        }
        .outro {
            font-size: 8px;
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 300px) {
    .gallery {
        .title {
            h1 {
                top: 9%;
                font-size: 38px;
            }
        }
        

        .outro {
            font-size: 8px;
        }
    }
}