.eco {
    img {
        width:100%;
        height: auto;
        margin-top: 5%;
    }
    button {
        position: absolute;
        z-index: 100;
        height: 50px;
        margin: 10px;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 20px;
        background-color: black;
        color: white;
        font-weight: bold;
        top: 46%;
        left: 29%;
        &:hover {
            background-color: white;
            border: 2px solid black;
            color: black;
            cursor: pointer;
        }
        &:focus {
            outline: none;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1520px) {
    .eco {
        button {
            top: 46%;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1280px) {
    .eco {
        img {
            margin-top: 8%;
        }
        button {
            top: 38%;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1024px) {
    .eco {
        img {
            margin-top: 10%;
        }
        button {
            top: 35%;
            left: 26%;
            font-size: 15px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 912px) {
    .eco {
        img {
            margin-top: 12%;
        }
        button {
            top: 34%;
            left: 26%;
            font-size: 13px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 820px) {
    .eco {
        img {
            width: 132%;
        }
        button {
            top: 38%;
            left: 34%;
            font-size: 13px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 768px) {
    .contact {
        .content {
            height: 2061px;
            
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 680px) {
    .eco {
        img {
            width: 132%;
        }
        button {
            top: 31%;
            left: 34%;
            font-size: 13px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 540px) {
    .eco {
        img {
            margin-top: 18%;
            width: 140%;
        }
        button {
            top: 28%;
            left: 34%;
            font-size: 10px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 470px) {
    .eco {
        img {
            margin-top: 22%;
            width: 140%;
        }
        button {
            height: 30px;
            padding:5px;
            top: 28%;
            left: 34%;
            font-size: 8px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 414px) {
    .eco {
        img {
            margin-top: 22%;
            width: 140%;
        }
        button {
            height: 30px;
            padding:5px;
            top: 25%;
            left: 34%;
            font-size: 8px;
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 400px) {
    .home {
        .content {

        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 390px) {
    .eco {
        img {
            margin-top: 26%;
            width: 140%;
        }
        button {
            height: 30px;
            padding:5px;
            top: 25%;
            left: 34%;
            font-size: 8px;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 375px) {
    .contact {
        img{
            object-fit: cover;
            width: 100%;
            &.clouds {
                width:100%;
            }
        }
        .content{
            .form {
                form {
                    width: 100%;
                    
                    label {
                        font-size: 20px;
                        color: white;
                    }
                    input {
                        width: 89%;
                    }
                    textarea {
                        width: 89%;
                    }
          
                }
                
            }
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 320px) {
    .eco {
        img {
            margin-top: 32%;
        }
        button {
            top: 22%;
        }
    }
}