.services {
    .title{
        h1 {
            text-align: center;
            position: absolute;
            top: 40%;
            z-index: 100;
            color: white;
            font-size: 82px;
            width: 100%;
            text-shadow: 2px 2px 4px #000000;
        }
    }
    
    .statue {
        position:relative;
        padding-top: 5.5%;
        iframe {
            border:none;
            position:relative;
            top:0;
            width: 100%;
            height: 1049px;
            object-fit: cover;
        }
    }
   
    .content {
        position: relative;
        z-index: 100;
        top: 20%;
        width: 100%;
        color: white;
        text-align: center;
        width: 100%;
        margin: 20px;
        font-size: 50px;
        //text shadow
        .offers {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            width: 100%;
            
            h2 {
                text-align: center;
                font-size: 35px;
            }
            p{
                font-size: 25px;
            }
            div {
                // background: rgba(255, 255, 255, 0.1);
                border-radius: 25px;
                padding: 10px;
                width: 56%;
                margin: 20px;
                .preview {
                    position: relative;
                    margin: 0;
                    width: 100%;
                    height: auto;
                    padding: 0;
                    iframe {
                        border: none;
                        position: relative;
                        top: 0;
                        width: 100%;
                        height: 544px;
                        object-fit: cover;

                    }
                    &.model {
                        iframe {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
    hr {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .outro {
        background: black;
        color: white;
        text-align: center;
        width: 100%;
        padding:4% 0;
        button {
            background-color: #141110;
            border: 2px solid white;
            border-radius: 25px;
            padding: 10px;
            font-size: 25px;
            margin: 20px;
            color: white;
            &:hover {
                background-color: white;
                color: #141110 !important;
            }
            a {
            text-decoration: none;
            color: white;
            padding:20px;
            &:hover {
                color: #141110 !important;
            }

            }
        }
        p{
            font-size: 20px;
            font-weight: bold;
        }
        .social {
            width: 100%;
            margin: 0 auto;
            
            .ig {
                width: 5%;
                margin-right: 2%;
                filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
            }
            .youtube {
                width: 5%;
                filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1664px) {
    .services {
       .statue {
            padding-top: 2.5%;
       }
       .content {
            margin-top: 0.5%;
       }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1520px) {
    .services {
        .statue {
            padding-top: 0%;
        }
        .content {
            margin-top: -3.5%;
        }
        
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1334px) {
    .services {
        .statue {
            iframe {
                margin-top: -6%;
            }
        }
        .content {
            margin-top: 0;
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1024px) {
    .services {
        .title {
            h1 {
                top: 24%;
            }
        }
        .statue {
            padding-top: 24%;
            iframe {
                margin-top: -15%;
                height: 581px;
            }
        }
        .content {
            .offers {
                div {
                    .preview{
                        &.model {
                            iframe {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 912px) {
    .services {
        .title {
            h1 {
                top: 24%;
            }
        }
        .statue {
            padding-top: 24%;
            iframe {
                margin-top: -13%;
                height: 521px;
            }
        }
        .content {
            .offers {
                div {
                    width: 84%;
                    .preview{
                        iframe {
                            height: 429px;
                        }
                    }
                }
            }
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 820px) {
    .services {
        .statue {
            iframe {
                margin-top: -12%;
                height: 466px;
            }
        }
    }
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 540px) {
    .services {
        .title {
            h1 {
                top: 20%;
                font-size: 58px;
            }
        }
        .content {
            margin: 0px;
            .offers {
                div {
                    margin: 0px;
                    width: 95%;
                    .preview {
                        iframe {
                            height: 299px;
                        }
                    }
                }
            }
        }
        .statue {
            iframe {
                margin-top: -4%;
                height: 303px;
            }
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 470px) {
    .services {
        .title {
            h1 {
                top: 20%;
                font-size: 50px;
            }
        }
        .content {
            margin: 0px;
            .offers {
                div {
                    margin: 0px;
                    width: 95%;
                    .preview {
                        iframe {
                            height: 260px;
                        }
                    }
                }
            }
        }
        .statue {
            iframe {
                margin-top: -2%;
                height: 262px;
            }
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 400px) {
    .services {
        .title {
            h1 {
                top: 16%;
                font-size: 50px;
            }
        }
        .content {
            margin: 0px;
            .offers {
                div {
                    margin: 0px;
                    width: 95%;
                    .preview {
                        iframe {
                            height: 219px;
                        }
                    }
                }
            }
        }
        .statue {
            iframe {
                margin-top: -1%;
                height: 228px;
            }
        }
        .outro {
            font-size: 8px;
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
// /// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 300px) {
    .services {
        .title {
            h1 {
                top: 16%;
                font-size: 41px;
            }
        }
        .content {
            margin: 0px;
            .offers {
                p {
                    font-size: 17px;
                }
                div {
                    margin: 0px;
                    width: 95%;
                    .preview {
                        iframe {
                            height: 167px;
                        }
                    }
                }
            }
        }
        .statue {
            iframe {
                margin-top: 11%;
                height: 173px;
            }
        }
        .outro {
            font-size: 8px;
        }
    }
}