.home {
    padding-top: 5%;
    .homeVideo {
        position:relative;
        iframe {
            border:none;
            position:relative;
            margin: 0;
            height: 1080px;
        }
    }
    
    .intro {
        width: 100%;
        position: absolute;
        display: flex;
        top: 122%;
        flex-direction: row;
        height: 50%;
        z-index: 100;
        .text{
            background-color: black;
            width: 49%;
            color: white;
            padding: 20px;
            font-size: 33px;
            height: auto;
            p {
            margin:5px 0;

            }
        }
        .pics {
            width: 49%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 8px solid black;
                &.home1 {
                    top:50%;
                    position: absolute;
                    object-fit: cover;
                    width: 22%;
                    height: 50%;
                    right: 26%;
                }
                &.home2 {
                   width: 98%;
                }
            }
        }
    }
    .content {
        width: 100%;
        margin-top: 12%;
        height: 1500px;
        background: rgb(69,68,53);
        background: linear-gradient(180deg, rgba(69,68,53,1) 30%, rgba(0,0,0,1) 93%);
        img {
            width:100%;
            height: 1500px;
            object-fit: cover;
        }
        .services {
            position: absolute;
            top: 183%;
            color: white;
            h1{
                text-align: center;
                width: 100%;
                margin: 20px;
                font-size: 50px;
                //text shadow
                text-shadow: 2px 2px 4px #000000;
            }
            .offers {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: center;
                width: 100%;
                margin: 4% 10%;
                h2 {
                    text-align: center;
                    font-size: 35px;
                }
                p{
                    font-size: 25px;
                }
                div {
                    background: rgba(0,0,0,0.5);
                    border-radius: 25px;
                    padding: 10px;
                    width: 422px;
                    margin: 20px;
                }
            }
        }   
    }
    hr {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .outro {
        background: black;
        color: white;
        text-align: center;
        width: 100%;
        padding:4% 0;
        button {
            background-color: #141110;
            border: 2px solid white;
            border-radius: 25px;
            padding: 10px;
            font-size: 25px;
            margin: 20px;
            color: white;
            &:hover {
                background-color: white;
                color: #141110 !important;
            }
            a {
            text-decoration: none;
            color: white;
            padding:20px;
            &:hover {
                color: #141110 !important;
            }

            }
        }
        p{
            font-size: 20px;
            font-weight: bold;
        }
        .social {
            width: 100%;
            margin: 0 auto;
            
            .ig {
                width: 5%;
                margin-right: 2%;
                filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
            }
            .youtube {
                width: 5%;
                filter: invert(93%) sepia(0%) saturate(7500%) hue-rotate(108deg) brightness(107%) contrast(110%);
            }
        }
        
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1664px) {
    .home {
        padding-top: 6.2%;
        .intro {
            top: 112%;
        }
        .content {
            margin-top: 28%;
            .services {
                top: 177%;
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1520px) {
    .home {
        padding-top: 6.9%;
        video {
            height: 600px;
        }
        .intro {
            position: relative;
            .text{
                font-size: 31px;
                p {
                    margin:20px 0;
                }
            }
            .pics {
                img {
                    width: 100%;
                }
            }
        }
        .content {
            margin-top: 0%;
            height: 1687px;
            img {
                height: 1687px;
            }
            .services {
                top: 179%;
                width: 100%;
                h1{
                    text-shadow: 2px 2px 4px #000000;
                }
                .offers {
                    flex-direction: column;
                    margin: auto;
                    p{
                        font-size: 20px;
                    }
                    div {
                        width: 82%;
                    }
                }
            }   
        }
        .outro {
            h1 {
                font-size: 30px;
                padding: 3%;
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1334px) {
    .home {
        padding-top: 7.9%;
        .content {
            .services {
                top: 162%;
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1280px) {
    .home {
        padding-top: 7.9%;
        video {
            height: 600px;
        }
        .intro {
            position: relative;
            .text{
                font-size: 31px;
                p {
                    margin:20px 0;
                }
            }
            .pics {
                img {
                    width: 100%;
                }
            }
        }
        .content {
            margin-top: 0%;
            height: 1790px;
            img {
                height: 1790px;
            }
            .services {
                top: 153%;
                width: 100%;
                h1{
                    text-shadow: 2px 2px 4px #000000;
                }
                .offers {
                    flex-direction: column;
                    margin: auto;
                    p{
                        font-size: 20px;
                    }
                    div {
                        width: 82%;
                    }
                }
            }   
        }
        .outro {
            h1 {
                font-size: 30px;
                padding: 3%;
            }
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 1024px) {
    .home {
        padding-top: 9.9%;
        .intro {
            .text{
                font-size: 23px;
            }
            .pics {
                img {
                    width: 100%;
                }
            }
        }
        .content {
            height: 1847px;
            img {
                height: 1847px;
            }
            .services {
                top: 126%;
            }   
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 912px) {
    .home {
        padding-top: 11.2%;
        .intro {
            .text{
                font-size: 20px;
                padding-left: 4%;
            }
            .pics {
                img {
                    &.home2 {
                       width: 98%;
                    }
                }
            }
        }
        .content {
            height: 1687px;
            img {
                height: 1687px;
            }
            .services {
                top: 113%;
                .offers {
                    h2 {
                        font-size: 30px;
                    }
                }
            }   
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 820px) {
    .home {
        padding-top: 12.3%;
        .content {
            width: 100%;
            height: 1790px;
            img {
                height: 1790px;
            }
            .services {
                top: 107%;
                h1{
                    width: 100%;
                }
                .offers {
                    p{
                        font-size: 20px;
                    }
                    div {
                        width: 82%;
                    }
                }
            }   
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 768px) {
    .home {
        padding-top: 13.3%;
        .content {
            height: 2061px;
            img {
                height: 2061px;
            }
            .services {
                top: 115%;
            }   
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 540px) {
    .home {
        padding-top: 19.3%;
        .intro {
            .text{
                font-size: 12px;
            }
        }
        .content {
            height: 2200px;
            img {
                height: 2200px;
            }
            .services {
                top: 93%;
                .offers {
                    h2 {
                        font-size: 25px;
                    }
                    
                }
            }   
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 470px) {
    .home {
        padding-top: 21.7%;
        .homeVideo {
            iframe {
                border:none;
                position:relative;
                margin: 0;
                height: 300px !important;
            }
        }
        .intro {
            flex-direction: column;
            background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(124,156,155,1) 93%);
            border-bottom: 1px solid white;
            .text{
                font-size: 20px;
                width: 91%;
                p {
                margin:10px 0;
                padding: 0 10px;
    
                }
            }
            .pics {
                width: 97%;
                padding: 1px;
                img {
                    width: 95%;
                    height: 95%;
                    &.home2 {
                        height: auto;
                    }
                    &.home1 {
                        top:auto;
                        position: relative;
                        width: 98%;
                        height: auto;
                        right: auto;
                    }
                }
            }
        }
        .content {
            height: 1687px;
            img {
                height: 1687px;
            }
            .services {
                top: 152%;
                .offers {
                    h2 {
                        font-size: 20px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }   
        }
       
        .outro {
            h1 {
                font-size: 20px;
            }
            button {
                padding: 10px;
                &:hover {
                    background-color: white;
                    color: #141110;
                }
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 414px) {
    .home {
        padding-top: 24.7%;
        .homeVideo {
            iframe {
                border:none;
                position:relative;
                margin: 0;
                height: 300px !important;
            }
        }
        .intro {
            flex-direction: column;
            background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(124,156,155,1) 93%);
            border-bottom: 1px solid white;
            .text{
                font-size: 20px;
                width: 91%;
                p {
                margin:10px 0;
                padding: 0 10px;
    
                }
            }
            .pics {
                width: 97%;
                padding: 1px;
                img {
                    width: 95%;
                    height: 95%;
                    &.home1 {
                        top:auto;
                        position: relative;
                        width: 98%;
                        height: auto;
                        right: auto;
                    }
                }
            }
        }
        .content {
            height: 1787px;
            img {
                height: 1687px;
            }
            .services {
                top: 166%;
                .offers {
                    h2 {
                        font-size: 20px;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }   
        }
       
        .outro {
            h1 {
                font-size: 20px;
            }
            button {
                padding: 10px;
                &:hover {
                    background-color: white;
                    color: #141110;
                }
            }
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 400px) {
    .home {
        padding-top: 25.7%;
        .content {
            .services {
                top: 196%;
            }   
        }
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 390px) {
    .home {
        .content {
            .services {
                top: 173%;
            }   
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 375px) {
    .home {
        padding-top: 26.7%;
        .intro {
            .text{
                width: 100%;
                color: white;
                padding: 0px;
                height: auto;
            }
            .pics {
                width: 100%;
                padding: 1px;
            }
        }
        .content {
            width: 100%;
            height: 1887px;
            img {
                height: 1887px;
            }
            .services {
                top: 200%;
                width: 100%;
                
                .offers {
                    p{
                        font-size: 15px;
                    }
                }
            }   
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 300px) {
    .home {
        padding-top: 33.7%;
        .content {
            .services {
                top: 150%;
            }
        }
    }
}